import { n as normalizeComponent } from "./index.js";
import "lodash";
import "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", { attrs: { "title": "Edit Printer", "has-deactivate-button": false, "has-delete-button": true } }, [_c("text-input", { key: "name", attrs: { "form-item": "", "rules": "required|max:40", "label": "Name", "placeholder": "Type Name", "max-length": 40, "span": 24 } }), _c("text-input", { key: "description", attrs: { "form-item": "", "rules": "max:50", "label": "Description", "placeholder": "Type Description", "max-length": 50, "span": 24 } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditPrinter"
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditPrinter = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-printer" }, [_c("resource", { attrs: { "name": "cf.label-printers", "api-url": _vm.apiUrl, "edit": _vm.EditPrinter, "redirect-route": "/container-filling/label-printers" } })], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "EditPrinter",
  data() {
    return {
      EditPrinter,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
